<template>
    <div class="c-app flex-row align-items-center">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-4">
                    <h1 class="text-center">
                        Controlador stock casero
                    </h1>
                    <div class="card-group">
                        <div class="card p-4">
                            <div class="card-body">
                                <form @submit.prevent="login">
                                    <p class="text-muted">Inicia sesión para ver la información</p>
                                    <div>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <font-awesome-icon icon="at" />
                                            </div>
                                            </div>
                                            <input type="email" class="form-control" v-model="form.email" placeholder="Email">
                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <font-awesome-icon icon="lock" />
                                            </div>
                                            </div>
                                            <input type="password" class="form-control"  v-model="form.password" placeholder="Contraseña">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="text-left col-6">
                                            <button type="submit" class="btn px-4 btn-primary" v-loading-btn="{loading: loading}">
                                                Ingresar
                                            </button>
                                        </div>
                                        <div class="text-right col-6">
                                            <button type="button" class="btn px-0 btn-link" @click="enConstruccion()">Olvide mi contraseña</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import getAxios from '../../utils/axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faIdCard, faLock, faAt } from '@fortawesome/free-solid-svg-icons';

library.add([faIdCard, faLock, faAt]);

export default {
    name: 'Login',
    data: () => {
        return {
            form: {
                email: null,
                password: null
            },
            loading: false
        };
    },
    methods: {
        enConstruccion() {
            alert("En construcción!");
        },
        login() {
            let self = this;

            if(self.form.email) {
                self.loading = true;
                self.axios.post(`login`, {
                    'email': self.form.email,
                    'password': self.form.password
                })
                    .then(function (response) {
                        console.log("response", response)
                        if(response.error) {
                            self.loading = false;
                            self.$toastr.e(response.mensaje, "Error");
                        } else {
                            self.$toastr.s("Datos correctos, bienvenido", "Correcto!");
                            // localStorage.setItem('stock_usuario', JSON.stringify(response.data));
                            self.$localStorage.set('stock_usuario', JSON.stringify(response.data));
                            self.$router.push({path: '/dashboard'});
                        }
                    }).catch(function (error) {
                        self.loading = false;
                        self.$toastr.e(error, "Exception");
                    });
            } else {
                self.$toastr.e("Debe ingresar los valores", "Error");
            }
        }
    }
}
</script>
